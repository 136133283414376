import './HelpAndSupportGreatformsPage.scss';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import { kebabCase } from 'lodash';
import { ContentTitler } from '@property-folders/components/dragged-components/ContentTitler';
import { CrumbDefn } from '@property-folders/common/types/BreadCrumbTypes';
import { greatformsHeading, helpAndSupportHeading } from './HelpAndSupportPage';
import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';
import type { PropsWithChildren, VideoHTMLAttributes } from 'react';
import type { Media } from '@property-folders/services/api/handler/private-static/constants';

interface HelpAndSupportGreatformsItemProps extends PropsWithChildren {
  heading: string;
  videoSrc: keyof typeof Media;
}

export function HelpAndSupportGreatformsPage() {
  const { hash } = useLocation();
  const breadcrumbs: CrumbDefn[] = [
    { label: helpAndSupportHeading, href: '/help-and-support' },
    { label: greatformsHeading },
    { label: '' }
  ];

  // Scroll to section on page load.
  useEffect(() => {
    const element = document.getElementById(hash.replace('#', ''));
    element && element.scrollIntoView({ behavior: 'smooth' });
  }, [hash]);

  function HelpAndSupportGreatformsItem({ heading, videoSrc, children }: HelpAndSupportGreatformsItemProps) {
    const id = kebabCase(heading);
    const disableContextMenu: VideoHTMLAttributes<any>['onContextMenu'] = (e) => e.preventDefault();

    return <Card id={id} className='card shadow wiz-card mx-0 mx-sm-2 mx-md-4'>
      <Card.Header>
        <a href={`#${id}`} className='page-link'>
          <h3 className='m-0'>{heading}</h3>
        </a>
      </Card.Header>

      <Card.Body>
        <div className='d-flex col-12 col-md-8 col-lg-6 mx-auto mt-3 video-container'>
          <video
            onContextMenu={disableContextMenu}
            tabIndex={-1}
            className='w-100'
            controls
            controlsList="nodownload"
          >
            <source src={LinkBuilder.restApi(`/private/static/${videoSrc}`)} type="video/mp4" />
          </video>
        </div>
        {children && <div className='mt-5'>
          {children}
        </div>}
      </Card.Body>
    </Card>;
  }

  return <ContentTitler title={greatformsHeading} breadcrumbs={breadcrumbs} flex>
    <div className='overflow-auto main-content h-100'>
      <div className='wizard-form'>

        <HelpAndSupportGreatformsItem heading='How to log into Greatforms?' videoSrc='helpLoginToGreatformsVideo'>
          <p>In this video, you’ll learn how to log into Greatforms through reaforms.</p>
        </HelpAndSupportGreatformsItem>

        <HelpAndSupportGreatformsItem heading='How to create Property Folders?' videoSrc='helpCreatingPropertyFoldersVideo'>
          <p>In this video, you'll learn how to create a Property Folder in Greatforms. Greatforms will automatically validate and populate the property details. Next, you’ll add vendor information, either by ordering details from Land Services SA or manually inputting them. Finally, you’ll assign a sales agent and create the Property Folder, completing the setup. It's a simple and efficient process to organize all property documents.</p>
        </HelpAndSupportGreatformsItem>

        <HelpAndSupportGreatformsItem heading='How to upload and sign any documents related to a property?' videoSrc='helpUploadingAndSigningAnyDocumentInGreatformsVideo'>
          <p>In this video, you’ll see how to upload documents to a Property Folder in Greatforms and get them signed by any party. Start by uploading a PDF to the 'All Documents' tile within the Property Folder. After previewing the document, click on 'Signing' to choose or add signing parties. Then, assign signing and contact fields to the document. Finally, click 'Submit for signing' to complete the process. It’s an easy and efficient way to handle document signing!</p>
        </HelpAndSupportGreatformsItem>

        <HelpAndSupportGreatformsItem heading='How to create, edit or clone Marketing Templates in Greatforms?' videoSrc='helpCreatingMarketingTemplatesVideo'>
          <p>In this video, you’ll learn how to create your own branded Marketing Templates. With admin access, navigate to Agency Settings and Marketing Templates to create a new template. You can add a header image or title text, then create sections for individual items or packages. Customize each item with checkboxes for required or default selection, and lock items to prevent edits. Once done, save your template and easily create marketing quotes in agency agreements in minutes.</p>
        </HelpAndSupportGreatformsItem>

        <HelpAndSupportGreatformsItem heading='How to add Marketing via templates to a Sales Agreement?' videoSrc='helpAddMarketingTemplateToSaaVideo'>
          <p>In this video, you'll see how to add marketing to a sales agreement using Greatforms templates. Start by selecting a preloaded template from the 'Marketing and Other Costs' section. All template selections automatically carried over. You can adjust editable items, select or unselect optional items, and add new ones. To add new sections or packages, simply click the appropriate option. Greatforms will automatically calculate totals. It’s a quick and easy way to manage marketing quotes within sales agreements!</p>
        </HelpAndSupportGreatformsItem>

        <HelpAndSupportGreatformsItem heading='How to add, edit or delete users in Greatforms?' videoSrc='helpAddEditUpdateUsersInGreatformsVideo'>
          <p>In this video, you’ll learn how to manage users. With admin access, go to Agency Settings and click on Users. To add a new user, click 'Add User' and enter their details, including name, email, and mobile number. If they are a salesperson, assign the Salesperson role. To edit or delete a user, click the three dots next to their name. For further assistance, you can contact support.</p>
        </HelpAndSupportGreatformsItem>

        <HelpAndSupportGreatformsItem heading='How to create ‘Disclosure of Benefits’ templates?' videoSrc='helpCreatingDisclosureOfBenefitsTemplatesVideo'>
          <p>In this video, you'll learn how to set up templates for disclosing benefits in Greatforms, making it easier for agents to disclose third-party benefits in property transactions. With admin access, go to Agency Settings and select Disclosure of Benefits. Click 'Add Template' to create a new template, where you can enter details like the nature of the benefit, source, amount, and recipient. Once saved, you can use these templates when creating agency agreements by selecting the "Yes" checkbox to disclose benefits. This streamlines the process and reduces repetitive tasks.</p>
        </HelpAndSupportGreatformsItem>

        <HelpAndSupportGreatformsItem heading='How to make changes or ‘Variations’ to a signed document?' videoSrc='helpCreatingDocumentVariationsVideo'>
          <p>In this video, you'll learn how to create variations of signed documents in Greatforms, allowing you to make changes and have them signed again. Start by navigating to the document in the Property Folder and clicking the "Create Variation" button. Go to the sections you need to modify and make the necessary changes. Click on "Signing" to have the document signed again. You can create multiple variations, and the most recent variation will appear on the left in the document folder.</p>
        </HelpAndSupportGreatformsItem>

        <HelpAndSupportGreatformsItem heading='How to sign on paper using Greatforms?' videoSrc='helpPaperSigningVideo'>
          <p>In this video, we'll show you how to sign on paper in Greatforms. Select "On Paper" as the signing method for the party and click "Submit." You’ll be directed to the "Signing in Progress" page, where you can download a print version of the document. After the party signs the document on paper, scan it and upload the signed version back into Greatforms. Enter the signing date, click "Continue," and submit the document. The document will now be marked as signed with a paper counterpart.</p>
        </HelpAndSupportGreatformsItem>

        <HelpAndSupportGreatformsItem heading='Signing features in Greatforms' videoSrc='helpSigningInGreatformsVideo'>
          <p>In this video, you'll explore the signing options available in Greatforms and how to configure them. After drafting your document, click the 'Signing' button to access the Configuring Signing page. Here, you can select how invitations will be sent—either in groups, individually, or all at once—and choose one of the 4 signing methods. If you select email link, you can personalize the message for each recipient. Once everything is set, click "Submit for Signing" to proceed. This process ensures you can manage signing invitations efficiently and according to your preferences</p>
        </HelpAndSupportGreatformsItem>

        <HelpAndSupportGreatformsItem heading='How to add your branding to Greatforms?' videoSrc='helpAddBrandingToGreatformsVideo'>
          <p>In this video, you'll learn how to update your agency’s branding in Greatforms. With admin access, go to Agency Settings and click on Email Branding. Upload your logo and adjust your brand colors using the color picker or by entering the exact RGB, HSL, or Hex code. You can also customize the branding for forms by selecting Greatforms Branding, where you can change colors for separators, headings, and the signing button. Finally, ensure your branding is included in the portal self-registration document for open inspections to maintain consistency across all materials.</p>
        </HelpAndSupportGreatformsItem>

        <HelpAndSupportGreatformsItem heading='How to create a contract and add conditions?' videoSrc='helpAddConditionsToContractVideo'>
          <p>In this video, you’ll learn how to create a contract in Greatforms. Start by navigating to the Property folder and clicking "Create" under Contract Management. The wizard on the left guides you through the document creation, with a live preview on the right. Vendor and property details are automatically populated from the latest signed sales agreement. Complete all required fields (marked with a red asterisk) for compliance. You can easily add conditions, such as finance or subject-to-sale, and customize them as needed. Once done, click "Signing" to have the contract signed by all parties. It's a quick and seamless process to finalize contracts!</p>
        </HelpAndSupportGreatformsItem>

        <HelpAndSupportGreatformsItem heading='How to create a Sales Agency Agreement?' videoSrc='helpCreatingSaaVideo'>
          <p>In this video, you'll learn how to create an agency agreement in Greatforms. Start by opening the Property Folder for the relevant property and clicking "Create" on the Sales Agency Agreement tile. The wizard on the left guides you through the document creation, with a live preview on the right. Property, vendor, and agent details are automatically populated. Complete all required fields (marked with a red asterisk) to ensure compliance. Once all information is entered, click "Signing" to have the agreement signed by the vendor. It’s a quick and easy way to secure your listing!</p>
        </HelpAndSupportGreatformsItem>

        <HelpAndSupportGreatformsItem heading='How to set up the Purchaser Portal?' videoSrc='helpCreatingPurchaserPortalsVideo'>
          <p>In this video, you'll learn how to set up the Purchaser Portal in Greatforms. Start by opening the Property Folder and clicking "Purchaser Management." Use "Change Process for Property" to decide what buyers can access, such as the property brochure, Form 1, the template contract, and the option to make offers (buyers must register first). Click "Apply" when done. To upload a brochure, select "Document" and upload a PDF. Share the portal link or QR code by clicking "Self-registration" to copy the link or download the QR code for marketing materials. To invite buyers, click "Invite," enter their details, and send the invitation email.</p>
        </HelpAndSupportGreatformsItem>

        <HelpAndSupportGreatformsItem heading='How to void a signing session?' videoSrc='helpVoidSigningVideo'>
          <p>In this video, you'll learn how to make changes to a document submitted for signing by voiding the signing process. Note: Signing can be voided only before any party signs. Simply open the document, click the "Void Signing" button, and choose whether to notify the parties. The document will return to draft status, allowing you to make edits and resend it for signing.</p>
        </HelpAndSupportGreatformsItem>

        <HelpAndSupportGreatformsItem heading='Template contracts in Greatforms' videoSrc='helpTemplateContractsInGreatformsVideo'>
          <p>In this video, you'll learn how Greatforms creates a "Template" contract when a Sales Agreement is signed for a property. You can view the Template contract in ‘Contract Management’. Template contract sets default values for any contracts created in that property folder. Any changes to the Template will only apply to new contracts, while existing drafts must be updated manually. Greatforms also tracks changes and ensures compliance with future variations to the Sales Agency Agreement.</p>
        </HelpAndSupportGreatformsItem>

        <HelpAndSupportGreatformsItem heading='How to upload a Form 1 to have it served automatically?' videoSrc='helpUploadingForm1ToPropertyFolderVideo'>
          <p>In this video, you'll learn how to upload a Form 1 created by the Vendor's conveyancer to the property folder in Greatforms. Simply click the 'Upload' button on the Form 1 tile, select the PDF, and choose whether it needs to be signed by vendor and/or agent. After signing, you can add the purchaser's name, address and contract date tags and submit the Form 1. If you wish, it will then be automatically served to the purchasers when a contract is signed in that property folder.</p>
        </HelpAndSupportGreatformsItem>

        <HelpAndSupportGreatformsItem heading='How to instantly order a Form 1 through Greatforms?' videoSrc='helpOrderingForm1InGreatformsVideo'>
          <p>In this video, you'll discover how to instantly order a Form 1 through Eckermanns Property Forms in Greatforms. You can order it while creating an Agency Agreement or via the Form 1 tile in the Property Folder. After placing the order, you can track its status and communicate with a specialist if needed. Once ready, the Form 1 will be returned to the property folder, and when a contract is signed, if you wish, Greatforms can automatically add the purchaser's details and serve it instantly.</p>
        </HelpAndSupportGreatformsItem>

        <HelpAndSupportGreatformsItem heading='How to edit existing property folder details?' videoSrc='helpEditingExistingPropertyFoldersVideo'>
          <p>In this video, you'll learn how to update an existing property folder in Greatforms. Simply open the folder, click the Edit button, and modify the property details, ensuring the address is validated with Land Services SA. You can also add or remove vendors and agents, and any new documents created will automatically use the updated information.</p>
        </HelpAndSupportGreatformsItem>
      </div>
    </div>
  </ContentTitler>;
}
