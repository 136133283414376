import './HelpAndSupportPage.scss';
import { ContentTitler } from '@property-folders/components/dragged-components/ContentTitler';
import { Link } from 'react-router-dom';
import { Row, Col, Card, Container } from 'react-bootstrap';
import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';
import { Icon } from '@property-folders/components/dragged-components/Icon';
import { useFeatureFlags } from '@property-folders/components/hooks/useFeatureFlags';

export const helpAndSupportHeading = 'Help and Support';
export const greatformsHeading = 'Greatforms Tutorials';

// Helpful tips: https://polaris.shopify.com/content/help-documentation

export function HelpAndSupportPage() {
  const featureFlags = useFeatureFlags();
  const greatformsLogo = LinkBuilder.propertiesImages('greatforms_logo_strap.svg');
  const reaformsLogo = LinkBuilder.propertiesImages('reaforms_logo_strap.svg');

  return (
    <ContentTitler title={helpAndSupportHeading} flex>
      <div className="overflow-auto main-content h-100">
        <Container>
          <Row className="mt-1 g-3">
            <Col className='col-12'>
              <Card className="card shadow hoverable mx-0 mx-sm-2 mx-md-4 support-card">
                <Card.Header>
                  <h3>Support</h3>
                </Card.Header>

                <Card.Body>
                  <h5 className='pb-2'>Contact Hours</h5>
                  <p>8:30am AEST to 5:30pm AEST, we will do our best to respond within 2 business hours, for phone and email.</p>
                  <p>We will respond quicker than that and outside of those hours wherever possible.</p>
                </Card.Body>

                <Card.Footer className="d-flex justify-content-end overflow-hidden">
                  <a
                    target="_blank"
                    className="btn btn-outline-secondary d-flex align-items-center"
                    href={LinkBuilder.reaformsMailToSupport}
                  >
                    <Icon name='mail' />
                    <div className='ps-1 d-none d-sm-block'>
                      support@reaforms.com.au
                    </div>
                  </a>
                  <a
                    target="_blank"
                    className="btn btn-outline-secondary d-flex align-items-center ms-2"
                    href={LinkBuilder.reaformsPhone}
                  >
                    <Icon name='call' />
                    <div className='ps-1 d-none d-sm-block'>
                      08 8166 1188
                    </div>
                  </a>
                </Card.Footer>
              </Card>
            </Col>

            {featureFlags.newPropertyFolders && <Col className='col-12 col-sm-6'>
              <Card className="card shadow hoverable mx-0 mx-sm-2 mx-md-4 support-card">
                <Card.Header>
                  <h3>{greatformsHeading}</h3>
                </Card.Header>

                <Card.Body className='d-flex justify-content-center'>
                  <img id="logo-large" alt="reaforms" src={greatformsLogo} className="d-inline-block w-50 py-5" style={{ maxHeight: '12rem' }} />
                </Card.Body>

                <Card.Footer className="d-flex justify-content-end">
                  <Link
                    className="btn btn-outline-secondary align-content-center"
                    to="/help-and-support/greatforms"
                  >
                    View
                  </Link>
                </Card.Footer>
              </Card>
            </Col>}

            <Col className={`col-12 col-sm-${featureFlags.newPropertyFolders ? '6' : '12'}`}>
              <Card className="card shadow hoverable mx-0 mx-sm-2 mx-md-4 support-card">
                <Card.Header>
                  <h3>reaforms Support Guide</h3>
                </Card.Header>

                <Card.Body className='d-flex justify-content-center'>
                  <img id="logo-large" alt="reaforms" src={reaformsLogo} className="d-inline-block w-50 py-5" style={{ maxHeight: '12rem' }} />
                </Card.Body>

                <Card.Footer className="d-flex justify-content-end">
                  <a
                    className="btn btn-outline-secondary align-content-center"
                    href={LinkBuilder.reaformsUserGuide}
                    rel="noopener noreferrer"
                  >
                    View
                  </a>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </ContentTitler>
  );
}
