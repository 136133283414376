import { BP_MINIMA } from '@property-folders/common/data-and-text/bootstrapBreakpoints';
import { CrumbDefn } from '@property-folders/common/types/BreadCrumbTypes';
import { FormContextType } from '@property-folders/common/types/FormContextType';
import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';
import { ShortId } from '@property-folders/common/util/url';
import { generateHeadlineFromMaterialisedData } from '@property-folders/common/yjs-schema/property';
import { FormTypes } from '@property-folders/common/yjs-schema/property/form';
import { SetupDebouncedAwarenessContext } from '@property-folders/components/context/DebouncedAwarenessContext';
import { FormContext } from '@property-folders/components/context/FormContext';
import { YjsDocContext } from '@property-folders/components/context/YjsDocContext';
import { ContentTitler } from '@property-folders/components/dragged-components/ContentTitler';
import { SetupNetStateContext } from '@property-folders/components/dragged-components/NetStateContext';
import { SigningFieldsConfiguration } from '@property-folders/components/dragged-components/signing/fieldConfiguration/SigningFieldsConfiguration';
import { FormContextSetup, SetupNetStateWritingYjsDocContext } from '@property-folders/components/form-gen-util/yjsStore';
import { useEntityLogoForCurrentPropertyFolder } from '@property-folders/components/hooks/useEntityLogo';
import { useFormLineageRootKey } from '@property-folders/components/hooks/useFormLineageRootKey';
import { useReactRouterData } from '@property-folders/components/hooks/useReactRouterHooks';
import { useLightweightTransaction } from '@property-folders/components/hooks/useTransactionField';
import { AwarenessData, FormCode, MaterialisedPropertyData, Maybe } from '@property-folders/contract';
import { RoomProvider } from '@y-presence/react';
import { useContext, useEffect, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useParams } from 'react-router-dom';
import { RouterData } from '~/App';

const initialPresence: AwarenessData = {
  location: {
    path: ''
  }
};

export function CustomCoverFieldConfigPage() {
  const { transId, ydoc, awareness } = useReactRouterData<RouterData>();
  const { documentIdRaw } = useParams();
  const { state } = useLocation();
  const formId = documentIdRaw
    ? ShortId.toUuid(documentIdRaw)
    : documentIdRaw;
  const { data: transactionRootKey, meta: transactionMetaRootKey, form } = useFormLineageRootKey(formId, ydoc);

  useEffect(() => {
    if (!form) return;
    if (!form?.upload?.editableAsCoverFor) {
      console.warn('form is not supposed to be used as a cover sheet', form);
    }
  }, [form]);

  const formContext = useMemo<Omit<FormContextType, 'formId'>>(() => {
    const formCode = form?.formCode ?? FormCode.UploadedDocument;
    return {
      formName: formCode,
      transactionRules: { _type: 'Map' },
      metaRules: { _type: 'Map' },
      printTitle: FormTypes[formCode].printTitle ?? FormTypes[formCode].label,
      wizardTitle: FormTypes[formCode].wizardTitle ?? FormTypes[formCode].printTitle ?? FormTypes[formCode].label
    };
  }, [!!form]);

  return ydoc && awareness && form && formContext
    ? <SetupNetStateContext ydoc={ydoc} transactionRootKey={transactionRootKey}>
      <SetupNetStateWritingYjsDocContext
        ydoc={ydoc}
        awareness={awareness}
        docName={transId}
        transactionRootKey={transactionRootKey}
        transactionMetaRootKey={transactionMetaRootKey}
      >
        <RoomProvider<AwarenessData> awareness={awareness} initialPresence={initialPresence}>
          <SetupDebouncedAwarenessContext delay={0}>
            <FormContextSetup formId={form.id} base={formContext}>
              <CustomCoverFieldConfigPageInner documentName={form.upload?.name || 'Uploaded Document'} breadcrumbs={state?.breadcrumbs as Maybe<CrumbDefn[]>} />
            </FormContextSetup>
          </SetupDebouncedAwarenessContext>
        </RoomProvider>
      </SetupNetStateWritingYjsDocContext>
    </SetupNetStateContext>
    : <div>loading property...</div>;
}

function CustomCoverFieldConfigPageInner({ breadcrumbs, documentName }: { breadcrumbs?: CrumbDefn[], documentName: string }) {
  const { ydoc, transactionRootKey, transactionMetaRootKey, docName: propertyId } = useContext(YjsDocContext);
  const { formId, formName: formCode, wizardTitle } = useContext(FormContext);
  const entityLogoUri = useEntityLogoForCurrentPropertyFolder();
  const { value: transRoot } = useLightweightTransaction<MaterialisedPropertyData>({ 'myPath': '' });
  const narrowMode = useMediaQuery({ maxWidth: BP_MINIMA.sm });
  const headlineVal = generateHeadlineFromMaterialisedData(transRoot, narrowMode);
  const updatedBreadcrumb = useMemo<CrumbDefn[]>(()=>{
    if (breadcrumbs?.length) {
      return [
        ...breadcrumbs,
        { label: 'Edit Cover Sheet' }
      ];
    }

    return [
      ...(breadcrumbs ?? [{ label: 'Properties', href: '/properties/' }]),
      { label: headlineVal || 'Property Overview', href: `/properties/${LinkBuilder.seoFriendlySlug(propertyId || '', headlineVal)}` },
      { label: 'All Documents', href: `/properties/${LinkBuilder.seoFriendlySlug(propertyId || '', headlineVal)}/documents` },
      { label: documentName, href: `/properties/${LinkBuilder.seoFriendlySlug(propertyId || '', headlineVal)}/document/${LinkBuilder.seoFriendlySlug(formId)}` },
      { label: 'Edit Cover Sheet' }
    ];
  }, [breadcrumbs, headlineVal]);

  return <ContentTitler
    breadcrumbs={updatedBreadcrumb}
    title={'Edit Cover Sheet'}
    // afterTitle={afterTitle}
    flex={true}
  >
    <SigningFieldsConfiguration
      propertyId={propertyId || ''}
      formCode={formCode}
      formId={formId}
      entityLogoLoadedUri={entityLogoUri}
      ydoc={ydoc}
      yRootKey={transactionRootKey}
      yMetaRootKey={transactionMetaRootKey}
      // figure out if these can be removed
      prepareForSigning={async () => {}}
      setCustomiseScreen={() => {}}
    />
  </ContentTitler>;
}
