import { Button, Container, Modal } from 'react-bootstrap';
import { BaseItem, LazyInfiniteTableList } from '@property-folders/components/dragged-components/LazyInfiniteTableList';
import { AuthApi } from '@property-folders/common/client-api/auth';
import { Icon } from '@property-folders/components/dragged-components/Icon';
import { SearchBar } from '@property-folders/components/dragged-components/SearchBar';
import React, { useContext, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { EntitySettingsContext } from '~/pages/settings/EntitySettingsContext';
import { LegacyApi } from '@property-folders/common/client-api/legacyApi';
import { Maybe } from '@property-folders/contract';
import { AsyncButton } from '@property-folders/components/dragged-components/AsyncButton';
import { AjaxPhp } from '@property-folders/common/util/ajaxPhp';
import { ButtonVariant } from 'react-bootstrap/types';
import { useNavigate } from 'react-router-dom';
import { EditUserModal } from '~/pages/settings/tabs/EditUserModal';

interface ApiUserItem {
  AgentEmail: string;
  AgentID: number;
  AgentName: string;
  AgentPhone: string;
  DateLastLogin: string;
  LoginGUID: string;
}

interface UserItem extends BaseItem, ApiUserItem {
  adminId: number;
  isAdmin: boolean;
  searchable: string;
}

export function UserManagementEdit() {
  const navigate = useNavigate();
  const { entityPhpInfo } = useContext(EntitySettingsContext);
  const [filter, setFilter] = useState('');
  const [modalAgent, setModalAgent] = useState<Maybe<{ id: number, name: string } & ({action: 'edit' | 'delete'} | {action: 'reset', email: string})>>(undefined);
  const { data: session } = AuthApi.useGetAgentSessionInfo();
  const { data: unfilteredItems, refetch } = useQuery({
    queryKey: ['settings-user-list', entityPhpInfo?.entityId || -1],
    queryFn: async context => {
      if (!entityPhpInfo?.entityId) {
        return [];
      }
      const response = await fetch(`/legacy/Datatables/Ajax/Admin_UserList.php?EntityID=${entityPhpInfo?.entityId}`, {
        signal: context.signal
      });

      const parsed = await response.json() as {
        data?: { DT_RowId: string, entity: { ManagementID: number }, users_agents: ApiUserItem }[]
      };

      return (parsed?.data || []).map<UserItem>(item => ({
        ...item.users_agents,
        id: item.users_agents.AgentID,
        searchable: `${item.users_agents.AgentName} ${item.users_agents.AgentEmail} ${item.users_agents.AgentPhone}`.toLowerCase(),
        adminId: item.entity.ManagementID,
        isAdmin: item.entity.ManagementID === item.users_agents.AgentID
      }));
    }
  });

  const items = useMemo<UserItem[]>(() => {
    const substr = filter.toLowerCase();
    return unfilteredItems
      ?.filter(item => item.searchable.indexOf(substr) >= 0)
      ?.sort((a, b) => a.AgentName.localeCompare(b.AgentName)) || [];
  }, [unfilteredItems, filter]);

  const closeModal = () => {
    setModalAgent(undefined);
  };

  const deleteAgentHandler = async (agentId: number) => {
    if (!entityPhpInfo?.entityId) return;
    await LegacyApi.ajax('deleteuser', {
      EntityID: entityPhpInfo.entityId.toString(),
      AgentID: agentId.toString()
    });
    await refetch();
    closeModal();
  };

  const loginAsHandler = async (agentId: number) => {
    const success = await AjaxPhp.loginAs(agentId);
    if (success) {
      window.location.href = '/';
    }
    return success;
  };

  const resetAgentHandler = async (agentId: number) => {
    await LegacyApi.ajax('sendreset', { agentid: agentId.toString() });
    await refetch();
    closeModal();
  };

  const setAdminHandler = async (agentId: number) => {
    if (!entityPhpInfo?.entityId) return;
    await LegacyApi.ajax('admin_setmain', {
      EntityID: entityPhpInfo.entityId.toString(),
      AgentID: agentId.toString()
    });
    await refetch();
  };

  return <Container fluid={true} className='h-100 position-relative d-flex flex-column flex-start g-0'>
    {modalAgent?.action === 'delete' && <ConfirmModal
      title='Delete User'
      onCancel={closeModal}
      onConfirm={() => deleteAgentHandler(modalAgent.id)} confirmLabel='Delete' confirmVariant='danger'
    >
      <p>Are you sure you wish to delete the agent <strong>{modalAgent.name}</strong>?</p>
      <p>Any forms created by <strong>{modalAgent.name}</strong> will remain in the system.</p>
    </ConfirmModal>}
    {modalAgent?.action === 'reset' && <ConfirmModal
      title='Reset Password'
      onCancel={closeModal}
      onConfirm={() => resetAgentHandler(modalAgent.id)} confirmLabel='Reset' confirmVariant='primary'
    >
      <p>Are you sure you wish to reset and email a new password to <strong>{modalAgent.name} ({modalAgent.email})</strong>?</p>
    </ConfirmModal>}
    {modalAgent?.action === 'edit' && <EditUserModal
      onClose={closeModal}
      onSave={async () => {
        await refetch();
        closeModal();
      }}
      agentId={modalAgent.id}
      entityId={entityPhpInfo?.entityId}
    />}
    <div className='d-flex flex-wrap flex-row px-3 pt-3 mb-3 mb-lg-1 gap-2'>
      <div className='flex-grow-1 d-flex flex-row justify-content-end'>
        <Button
          variant='primary'
          size='lg'
          title='Add template'
          onClick={() => setModalAgent({ id: 0, name: 'New User', action: 'edit' })}
          style={{ paddingTop: 'calc(0.5rem - 0.5px)', paddingBottom: 'calc(0.5rem - 1px)' }}
        >
          <Icon name='add' variant='outlined' icoClass='fs-4 mb-1 me-2'></Icon>
          Add User
        </Button>
      </div>
      <div style={{ flexGrow: 999 }}>
        <SearchBar
          placeholder='Search for a user'
          onSearch={term => setFilter(term)}
          className='w-100'
          constrainWidth={false}
        />
      </div>
    </div>
    <LazyInfiniteTableList
      storageKey={'EntitySettingsUsers'}
      hasNextPage={false}
      items={items}
      isFetching={!unfilteredItems}
      columns={[{
        label: 'Name',
        rowMajor: row => row.AgentName,
        rowMinor: row => <small>{row.isAdmin ? 'Administrator' : ''}</small>
      }, {
        label: 'Email',
        rowMajor: row => row.AgentEmail
      }, {
        label: 'Mobile',
        rowMajor: row => row.AgentPhone
      }, {
        label: 'Last Login',
        rowMajor: row => row.DateLastLogin
      }]}
      rowActions={[{
        label: 'Edit',
        action: row => setModalAgent({ id: row.AgentID, name: row.AgentName, action: 'edit' })
      }, {
        label: 'Copy Token',
        action: async row => {
          return await copyText(row.LoginGUID)
            ? 'Token copied'
            : 'Could not copy token';
        }
      }, {
        label: 'Delete',
        action: row => setModalAgent({ id: row.AgentID, name: row.AgentName, action: 'delete' })
      }, {
        label: 'Login',
        dividerAbove: true,
        action: row => loginAsHandler(row.AgentID),
        if: () => Boolean(session?.isGlobalAdmin)
      }, {
        label: 'Reset Password',
        action: row => setModalAgent({ id: row.AgentID, name: row.AgentName, action: 'reset', email: row.AgentEmail }),
        if: () => Boolean(session?.isGlobalAdmin)
      },{
        label: 'Make Administrator',
        action: row => setAdminHandler(row.AgentID)
          .catch(console.error),
        if: () => Boolean(session?.isGlobalAdmin),
        disabled: row => row.isAdmin
      }]}
    />
  </Container>;
}

function ConfirmModal(props: React.PropsWithChildren<{
  title: string,
  onCancel: () => void,
  confirmVariant: ButtonVariant,
  confirmLabel: string,
  onConfirm: () => Promise<void>,
}>) {
  return <Modal show={true} onHide={props.onCancel}>
    <Modal.Header>
      <Modal.Title>{props.title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>{props.children}</Modal.Body>
    <Modal.Footer>
      <Button variant='outline-secondary' onClick={props.onCancel}>Cancel</Button>
      <AsyncButton variant={props.confirmVariant} onClick={props.onConfirm}>{props.confirmLabel}</AsyncButton>
    </Modal.Footer>
  </Modal>;
}

async function copyText(value: string) {
  if ('clipboard' in navigator && navigator.clipboard) {
    const clipboard = navigator.clipboard as Clipboard;
    try {
      await clipboard.writeText(value);
      return true;
    } catch (err: unknown) {
      console.error(err);
    }
  }

  const el = ensureCopyTextEl();
  el.value = value;
  el.select();

  return document.execCommand('copy');
}

function ensureCopyTextEl() {
  const el = document.getElementById('CopyTextHiddenField');
  if (el) {
    return el as HTMLInputElement;
  } else {
    const newEl = document.createElement('input');
    newEl.hidden = true;
    document.body.appendChild(newEl);
    return newEl;
  }
}
